<!-- eslint-disable -->
<template>
  <div class="page_box">
    <sb-headline title="险种管理"></sb-headline>
    <my-table ref="list" :data="listData" @page="pageHandler" :listLoading="listLoading">
      <template #header="params">
        <div class="search_box">
          <el-row :gutter="10">
            <el-col :span="5">
              <div class="search_item">
                <div>险种名称：</div>
                <el-input v-model="searchData.categoryName"></el-input>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="10" style="margin-top: 10px;">
            <el-col :span="4" :offset="20">
              <el-button @click="pageHandler(params, 1, 'reset')">重置</el-button>
              <el-button @click="pageHandler(params, 1)" type="primary">查询</el-button>
            </el-col>
          </el-row>
        </div>
      </template>
      <template #navBar>
        <div class="table_nav_bar">
          <el-button type="primary" @click="insuranceTypeAdd">新增险种</el-button>
        </div>
      </template>
      <el-table-column label="险种名称" fixed="left" prop="categoryName" :min-width="210"></el-table-column>
      <el-table-column label="险种类型" prop="type" :min-width="100">
        <template slot-scope="scope">
          <div v-if="scope.row.type==1">交强险</div>
          <div v-if="scope.row.type==2">商业险</div>
          <div v-if="scope.row.type==3">附加险</div>
          <div v-if="scope.row.type==4">代收车船税</div>
          <div v-if="scope.row.type==5">其他</div>
        </template>
      </el-table-column>
      <el-table-column label="险种说明" prop="description" :min-width="300"></el-table-column>
      <el-table-column label="险种案例" prop="caseDesc" :min-width="300"></el-table-column>
      <el-table-column label="更新时间" prop="mtime" :min-width="180"></el-table-column>
      <el-table-column label="操作" fixed="right" prop="" width="150">
        <template slot-scope="scope">
          <el-button class="handle_btn" type="text" size="mini" @click="tableHandle(1, scope.row)">编辑</el-button>
          <el-button class="handle_btn" type="text" size="mini" @click="tableHandle(2, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </my-table>
    <!-- 新增险种 -->
    <el-dialog title="险种操作" class="insurance_dialog" :visible.sync="insuranceTypeVisible" width="70%">
      <el-form :model="insuranceTypeForm" ref="insuranceTypeForm" :rules="insuranceTypeRule" label-width="100px">
        <div class="insurance_dialog_module">
          <el-form-item label="险种名称：" prop="categoryName">
            <el-input v-model="insuranceTypeForm.categoryName" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="险种类型：" prop="type">
            <el-radio-group v-model="insuranceTypeForm.type">
              <el-radio :label="1">交强险</el-radio>
              <el-radio :label="2">商业险</el-radio>
              <el-radio :label="3">附加险</el-radio>
              <el-radio :label="4">代收车船税</el-radio>
              <el-radio :label="5">其他</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="险种说明：" prop="description">
            <el-input v-model="insuranceTypeForm.description" type="textarea" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="险种案例：" prop="caseDesc">
            <el-input v-model="insuranceTypeForm.caseDesc" type="textarea" autocomplete="off"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogCancel">取 消</el-button>
        <el-button type="primary" @click="dialogConfirm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
    data() {
    //这里存放数据
      return {
        insuranceTypeVisible: false,
        insuranceTypeForm: {
          categoryCode: '',   //险种code
          categoryName: '',   //险种名称
          description: '',    //险种说明
          caseDesc: '',       //险种案例
          type: '',           //险种类型
        },
        insuranceTypeRule: {
          categoryName: [{ required: true, message: '请输入险种名称', trigger: 'blur' }],
          type: [{ required: true, message: '请选择险种类型', trigger: 'change' }]
        },
        listData: [],
        // 搜索数据
        searchData: {
          categoryName: "", //险种名称
        },
        listLoading: false, //列表loading
      };
    },
  filters: {
    stateFilter: function(val) {
      
    },
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    pageHandler(params, num, type) {
      if(type == 'reset'){
        this.resetSearchData();
      }
      this.getPackageList(params, num ? num : params.pageNum, params.pageSize);
    },
    // 表格内操作
    tableHandle(type, row) {
      console.log("type:" + type + " row:" + JSON.stringify(row));
      switch (type) {
        case 1:     //编辑
          this.insuranceTypeVisible = true;
          Object.keys(this.insuranceTypeForm).forEach(key => {
            this.insuranceTypeForm[key] = row[key];
          })
          break;
        case 2:     //删除
          this.$confirm('是否删除此条内容?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$store.dispatch('insurance/postInsuranceTypesDelete',{
              categoryCode: row.categoryCode
            }).then(res => {
              this.$refs.list.init();
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
            })
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            });
          });
          break;
        default:
          break;
      }
    },


    // 重置搜索数据
    resetSearchData(e) {
      this.searchData = {
        name: "",
      }
    },

    // 新增险种
    insuranceTypeAdd(){
      this.insuranceTypeVisible = true;
    },

    // 弹窗取消
    dialogCancel(){
      this.$refs.insuranceTypeForm.resetFields();
      this.insuranceTypeVisible = false;
    },
    // 弹窗确认
    dialogConfirm(){
      this.$refs.insuranceTypeForm.validate((valid) => {
        if (valid) {
          this.$store.dispatch('insurance/postInsuranceTypesSave',{
            ...this.insuranceTypeForm
          }).then(res => {
            this.$message.success('操作成功');
            this.$refs.insuranceTypeForm.resetFields();
            this.$refs.list.init();
            this.insuranceTypeVisible = false;
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },


    // 获取险种列表
    getPackageList(params,pageNum,pageSize) {
      this.listLoading = true;
      this.$store.dispatch('insurance/getInsuranceTypesList',{
        ...this.searchData,
        pageNum,
        pageSize,
      }).then(res => {
        if(res.code == 0){
          this.listData = res.data.list;
          params.render(res.data.total);
        }
        this.listLoading = false;
      })
    },

  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  //生命周期 - 创建之前
  beforeCreate() {},
  //生命周期 - 挂载之前
  beforeMount() {},
  //生命周期 - 更新之前
  beforeUpdate() {},
  //生命周期 - 更新之后
  updated() {},
  //生命周期 - 销毁之前
  beforeDestroy() {},
  //生命周期 - 销毁完成
  destroyed() {},
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},
}
</script>
<style lang="scss" scoped>
  //@import url(); 引入公共css类
  .page_box {
    padding: 0 20px;
    .search_box {
      background: #fff;
      padding: 20px;
      margin-top: 20px;
      .el-input{
        width: 100% !important;
      }
    }
    .table_nav_bar{
      width: 100%;
      background: #fff;
      padding: 20px 20px 0 20px;
      box-sizing: border-box;
      .el-button{
        margin-right: 10px;
      }
      i{
        color: #fff;
      }
    }
    .table_row_rule{
      word-break: break-all; /** 换行，长单词会分成两行显示 **/
      text-overflow: ellipsis;/** 隐藏文本省略号 ... **/
      display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
      -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
      -webkit-line-clamp: 1; /** 显示的行数 **/
      overflow: hidden;  /** 隐藏超出的内容 **/;
    }
  }
  .handle_btn{
    padding: 0;
  }
  .channel_item{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    >.el-input:nth-child(1){
      width: 260px;
    }
  }
  .channel_item:last-child{
    margin: 0;
  }
  .popularize_list_qrcode{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .qrcode_img_src{
    position: absolute;
    top: -10000%;
    z-index: -10000;
  }
  .search_item{
    display: flex;
    align-items: center;
    >div:first-child{
      flex-shrink: 0;
    }
  }
  .insurance_dialog_module{
    .el-input{
      width: 50% !important;
    }
  }
  ::v-deep .el-table__header thead tr th:first-of-type, .el-table__body tbody tr td:first-of-type{
    padding: 0 !important;
  }
  ::v-deep .el-table__body tbody tr td:first-of-type {
    padding-left: 0px !important;
  }
</style>
